import * as React from 'react';
import {
  PageSection,
  Title,
  Spinner,
  Gallery,
  GalleryItem,
  Card,
  CardTitle,
  CardBody,
  Alert,
  Popover,
} from '@patternfly/react-core';
import HelpIcon from '@patternfly/react-icons/dist/esm/icons/help-icon';
import { ContractKind, ContractStats, Distribution, ManagerContract, NodeStats } from 'linbit-api-fetcher';
import { apiGet, fetchDistributions, fetchStatsContracts, getCurrentClaims, queryContractKinds, queryIssuers } from './fetcher';
import { useQuery } from '@tanstack/react-query';
import {
  Chart, ChartAxis, ChartBar, ChartGroup, ChartLegend, ChartLine, ChartPie, ChartStack, ChartThemeColor, ChartVoronoiContainer
} from '@patternfly/react-charts';

// has to be defined outside of Dashboard or we have a recursion
const TODAY = new Date();

interface ContractKindStatsCardProps {
  kinds: Map<number, ContractKind>
}

const ContractKindStatsCard: React.FunctionComponent<ContractKindStatsCardProps> = (props: ContractKindStatsCardProps) => {
  async function fetchContractStats(): Promise<ContractStats> {
    let uri = '/manager/stats/contract';
    return apiGet<ContractStats>(uri);
  }

  function renderCardData(data: ContractStats) {
    const chartData = Object.keys(data.active.by_kind).map((kind_id) => {
      return {
        'x': props.kinds.get(parseInt(kind_id))?.name + ": " + data.active.by_kind[kind_id],
        'y': data.active.by_kind[kind_id]
      }
    });
    const legendData = chartData.sort((a, b) => b.y - a.y).map((e) => {
      return { 'name': e.x }
    });
    return (
      <ChartPie
        constrainToVisibleArea={true}
        data={chartData}
        legendOrientation='vertical'
        legendData={legendData}
        themeColor={ChartThemeColor.multiUnordered}
        padding={{
          bottom: 20,
          left: 20,
          right: 140, // Adjusted to accommodate legend
          top: 20
        }}
        width={300}
        height={300}
      />
    )
  }

  const { isLoading, isError, data, error } =
    useQuery<ContractStats, string>(['contract-stats'], fetchContractStats);

  if (isError) {
    return (<Card><CardTitle><Alert variant='danger' title={error} /></CardTitle></Card>)
  }

  const stats = data || { active: { contracts: 0, by_kind: {} } };

  return (
    <Card>
      <CardTitle><Title headingLevel='h2'>{stats.active.contracts} Active contracts</Title></CardTitle>
      <CardBody>
        {isLoading ? <Spinner /> : renderCardData(stats)}
      </CardBody>
    </Card>
  )
}

interface DistributionsCardProps {
}

const DistributionsCard: React.FunctionComponent<DistributionsCardProps> = (props: DistributionsCardProps) => {
  async function fetchNodeStats(): Promise<NodeStats> {
    let uri = '/manager/stats/nodes';
    return apiGet<NodeStats>(uri);
  }

  function createData(distriMap: Map<number, Distribution>, data: NodeStats) {
    const chartDataMap: { [key: string]: number } = {};
    for (const n of data.registered_active_nodes) {
      // if (n.distribution_id == null || n.distribution_id === 74) continue;
      const fullName = distriMap.get(n.distribution_id ? n.distribution_id : 74)?.name;
      let name = "Unknown";
      if (fullName) {
        if (fullName.startsWith("rhel8")) name = "rhel8";
        else if (fullName.startsWith("rhel7")) name = "rhel7";
        else if (fullName.startsWith("rhel6")) name = "rhel6";
        else if (fullName.startsWith("ol7")) name = "ol7";
        else if (fullName.startsWith("ol6")) name = "ol6";
        else name = fullName;
      }
      if (name in chartDataMap) chartDataMap[name]++; else chartDataMap[name] = 1;
    }

    const chartData = Object.keys(chartDataMap).map((k) => {
      return {
        'x': k + ": " + chartDataMap[k],
        'y': chartDataMap[k]
      }
    }).filter((cd) => cd.y > 10).sort((a, b) => b.y - a.y);

    const legendData = chartData.map((e) => {
      return { 'name': e.x }
    });
    return { data: chartData, legend: legendData };
  }

  function renderCardData(chartInfo: any) {
    return (
      <ChartPie
        constrainToVisibleArea={true}
        data={chartInfo.data}
        legendOrientation='vertical'
        themeColor={ChartThemeColor.multiOrdered}
        width={300}
        height={300}
      />
    )
  }

  const qryDistris = useQuery<Map<number, Distribution>, string>(['distributions'], fetchDistributions);

  const { isLoading, isError, data, error } =
    useQuery<NodeStats, string>(['stats', 'nodes'], fetchNodeStats);

  if (isError || qryDistris.isError) {
    return (<Card><CardTitle><Alert variant='danger' title={error} /></CardTitle></Card>)
  }

  const stats = data || { registered_active_nodes: [] };

  const chartInfo = createData(qryDistris.data ? qryDistris.data : new Map(), stats);
  return (
    <Card>
      <CardTitle>
        <Title headingLevel='h3'>{stats.registered_active_nodes.length} Registered nodes distris&nbsp;
          {isLoading || qryDistris.isLoading ? <Spinner isSVG /> :
            <Popover
              headerContent="Distris with less than 10 entries are filtered out."
              bodyContent={<ChartLegend
                data={chartInfo.legend} orientation="vertical" themeColor={ChartThemeColor.multiOrdered} />}>
              <button
                type="button"
                aria-label="filter rules description"
                onClick={e => e.preventDefault()}
                className="pf-c-form__group-label-help"
              ><HelpIcon noVerticalAlign /></button>
            </Popover>
          }
        </Title>
      </CardTitle>
      <CardBody>
        {isLoading || qryDistris.isLoading ? <Spinner isSVG /> : renderCardData(chartInfo)}
      </CardBody>
    </Card>
  )
}

interface RegisteredNodesPerYearCardProps {
  lastYears?: number,
}

const RegisteredNodesPerYearCard: React.FunctionComponent<RegisteredNodesPerYearCardProps> = (props: RegisteredNodesPerYearCardProps) => {
  let sinceYears = props.lastYears ? props.lastYears : 4;
  const chartBars: { [key: string]: ChartBarData[] } = {};

  async function fetchNodeStats(year: number): Promise<NodeStats> {
    let uri = '/manager/stats/nodes?date=' + year + '-01-01T00:00:00';
    return apiGet<NodeStats>(uri);
  }

  const queries = [];
  // staleTime 4h
  for (let i = TODAY.getFullYear() - sinceYears + 1; i <= TODAY.getFullYear(); i++) {
    queries.push(useQuery<NodeStats, string>(['stats', 'nodes', i], () => fetchNodeStats(i), { staleTime: 4 * 3600000 }))
  }

  if (queries.some((qry) => qry.isError)) {
    return <Alert title="Error loading node stats" />;
  }

  if (queries.some((qry) => qry.isLoading)) {
    return <Spinner isSVG />;
  }

  const chartData = queries.map((qry, idx) => {
    const data = qry.data ? qry.data : { registered_active_nodes: [] };
    return { name: "nodes", x: TODAY.getFullYear() - sinceYears + idx + 1, y: data.registered_active_nodes.length }
  });

  const chartLegend = [{ name: "Registered nodes" }];
  return (
    <Card>
      <CardTitle><Title headingLevel='h2'>Registered nodes per year</Title></CardTitle>
      <CardBody>
        <Chart
          containerComponent={<ChartVoronoiContainer labels={({ datum }) => `${datum.name}: ${datum.y}`} constrainToVisibleArea />}
          legendPosition='bottom-left'
          themeColor={ChartThemeColor.multiUnordered}
          legendData={chartLegend}
          legendAllowWrap
          padding={{ // Adjusted to accommodate legend
            bottom: 70,
            left: 60,
            right: 20,
            top: 20
          }}
          width={300}
          height={300}>
          <ChartAxis tickValues={chartData.map((d) => d.x)} />
          <ChartAxis dependentAxis showGrid />
          <ChartGroup>
            <ChartLine
              data={chartData}
            />
          </ChartGroup>
        </Chart>
      </CardBody>
    </Card>
  )
}

interface ContractOptionsStatsCardProps {
  contracts: ManagerContract[]
}

enum ContractStatsOption {
  HA_SDS = "HA & SDS",
  PacemakerOnly = "Pacemaker Only",
  HA = "HA",
  SDS = "SDS",
  DRBDOnly = "Drbd Only",
  ProxyDownload = "Proxy DL",
  Other = "Other",
}

function contractStatsOptionType(c: ManagerContract): ContractStatsOption {
  function isHAContract(c: ManagerContract) {
    return c.options.includes(8);
  }

  function isProxyDownloadContract(c: ManagerContract) {
    return c.options.includes(2);
  }

  function isSDSContract(c: ManagerContract) {
    return c.options.includes(10) || c.options.includes(11) || c.options.includes(12) || c.options.includes(13)
      || c.options.includes(14) || c.options.includes(15 || c.options.includes(17) || c.options.includes(18));
  }

  function isDrbd(c: ManagerContract) {
    return c.options.includes(4) || c.options.includes(5) || c.options.includes(7);
  }

  if (isHAContract(c) && isSDSContract(c)) {
    return ContractStatsOption.HA_SDS;
  } else {
    if (isHAContract(c)) {
      if (c.options.length == 1) {
        return ContractStatsOption.PacemakerOnly;
      } else {
        return ContractStatsOption.HA;
      }
    } else if (isSDSContract(c)) {
      return ContractStatsOption.SDS;
    } else if (isDrbd(c)) {
      return ContractStatsOption.DRBDOnly;
    } else {
      if (isProxyDownloadContract(c)) {
        return ContractStatsOption.ProxyDownload;
      }
    }
  }
  return ContractStatsOption.Other;
}

const ContractOptionsStatsCard: React.FunctionComponent<ContractOptionsStatsCardProps> =
  (props: ContractOptionsStatsCardProps) => {
    let mixedCount = 0;
    let haCount = 0;
    let pacemakeOnlyCount = 0;
    let sdsCount = 0;
    let drbdCount = 0;
    let proxyCount = 0;
    let otherCount = 0;
    for (const c of props.contracts) {
      switch (contractStatsOptionType(c)) {
        case ContractStatsOption.HA_SDS: mixedCount++; break;
        case ContractStatsOption.DRBDOnly: drbdCount++; break;
        case ContractStatsOption.HA: haCount++; break;
        case ContractStatsOption.PacemakerOnly: pacemakeOnlyCount++; break;
        case ContractStatsOption.ProxyDownload: proxyCount++; break;
        case ContractStatsOption.SDS: sdsCount++; break;
        default: otherCount++;
      }
    }

    const chartData = [
      { x: "HA: " + haCount, y: haCount },
      { x: "HA & SDS: " + mixedCount, y: mixedCount },
      { x: "SDS: " + sdsCount, y: sdsCount },
      { x: "DRBD Only: " + drbdCount, y: drbdCount },
      { x: "Pacemaker Only: " + pacemakeOnlyCount, y: pacemakeOnlyCount },
      { x: "Proxy DL: " + proxyCount, y: proxyCount },
      { x: "Other: " + otherCount, y: otherCount },
    ];

    const chartLegend = chartData.sort((a, b) => { return b.y - a.y }).map((l) => { return { name: l.x } });
    return (
      <Card>
        <CardTitle><Title headingLevel='h2'>Active contracts by options</Title></CardTitle>
        <CardBody>
          <ChartPie
            constrainToVisibleArea
            data={chartData}
            legendPosition='bottom'
            legendAllowWrap
            legendOrientation='horizontal'
            legendData={chartLegend}
            themeColor={ChartThemeColor.multiUnordered}
            padding={{ // Adjusted to accommodate legend
              bottom: 130,
              left: 20,
              right: 20,
              top: 20
            }}
            width={300}
            height={300}
          />
        </CardBody>
      </Card>
    )
  }

interface SDSOptionStatsCardProps {
  contracts: ManagerContract[]
}

const SDSOptionStatsCard: React.FunctionComponent<SDSOptionStatsCardProps> =
  (props: SDSOptionStatsCardProps) => {
    let cloudStackCount = 0;
    let proxmoxCount = 0;
    let openStackCount = 0;
    let k8Count = 0;
    let openNebulaCount = 0;
    let xcpngCount = 0;
    let oVirtCount = 0;
    let ganetiCount = 0;
    let customCount = 0;
    for (const c of props.contracts) {
      if (c.options.includes(10)) {
        openNebulaCount++;
      } else if (c.options.includes(11)) {
        proxmoxCount++;
      } else if (c.options.includes(12)) {
        openStackCount++;
      } else if (c.options.includes(13)) {
        k8Count++;
      } else if (c.options.includes(17)) {
        cloudStackCount++;
      } else if (c.options.includes(18)) {
        xcpngCount++;
      } else if (c.options.includes(21)) {
        oVirtCount++;
      } else if (c.options.includes(22)) {
        ganetiCount++;
      } else if (c.options.includes(23)) {
        customCount++;
      }
    }

    const chartData = [
      { x: "Proxmox: " + proxmoxCount, y: proxmoxCount },
      { x: "CloudStack: " + cloudStackCount, y: cloudStackCount },
      { x: "Kubernetes: " + k8Count, y: k8Count },
      { x: "OpenNebula: " + openNebulaCount, y: openNebulaCount },
      { x: "OpenStack: " + openStackCount, y: openStackCount },
      { x: "XCP-ng: " + xcpngCount, y: xcpngCount },
      { x: "oVirt: " + oVirtCount, y: oVirtCount },
      { x: "Ganeti: " + ganetiCount, y: ganetiCount },
      { x: "Custom: " + customCount, y: customCount },
    ];

    const chartLegend = chartData.sort((a, b) => { return b.y - a.y }).map((l) => { return { name: l.x } });
    return (
      <Card>
        <CardTitle>
          <Title headingLevel='h2'>Active contracts by SDS&nbsp;
            <Popover
              headerContent="Legend"
              bodyContent={<ChartLegend
                data={chartLegend} orientation="vertical" themeColor={ChartThemeColor.multiOrdered} />}>
              <button
                type="button"
                aria-label="filter rules description"
                onClick={e => e.preventDefault()}
                className="pf-c-form__group-label-help"
              ><HelpIcon noVerticalAlign /></button>
            </Popover>
          </Title>
        </CardTitle>
        <CardBody>
          <ChartPie
            constrainToVisibleArea
            data={chartData}
            legendPosition='bottom'
            legendAllowWrap
            legendOrientation='horizontal'
            // legendData={chartLegend}
            themeColor={ChartThemeColor.multiUnordered}
            // padding={{ // Adjusted to accommodate legend
            //   bottom: 130,
            //   left: 20,
            //   right: 20,
            //   top: 20
            // }}
            width={300}
            height={300}
          />
        </CardBody>
      </Card>
    )
  }

interface ContractsIssuedCardProps {
  contracts: ManagerContract[],
  lastYears?: number,
}

interface ChartBarData {
  name: string,
  x: string,
  y: number,
}

const ContractsIssuedCard: React.FunctionComponent<ContractsIssuedCardProps> = (props: ContractsIssuedCardProps) => {
  let sinceYears = props.lastYears ? props.lastYears : 4;
  const chartBars: { [key: string]: ChartBarData[] } = {};
  const optsView = [ContractStatsOption.HA_SDS, ContractStatsOption.HA, ContractStatsOption.SDS,
  ContractStatsOption.DRBDOnly, ContractStatsOption.PacemakerOnly, ContractStatsOption.ProxyDownload];
  optsView.forEach((cso) => chartBars[cso] = []);

  for (let i = TODAY.getFullYear() - sinceYears + 1; i <= TODAY.getFullYear(); i++) {

    let mixedCount = 0;
    let haCount = 0;
    let pacemakeOnlyCount = 0;
    let sdsCount = 0;
    let drbdCount = 0;
    let proxyCount = 0;
    let otherCount = 0;

    const yearsContracts = props.contracts.filter((cont) => {
      const cSupFromYear = +cont.support_from.substring(0, 4);
      return cSupFromYear === i;
    });
    for (const c of yearsContracts) {
      switch (contractStatsOptionType(c)) {
        case ContractStatsOption.HA_SDS: mixedCount++; break;
        case ContractStatsOption.DRBDOnly: drbdCount++; break;
        case ContractStatsOption.HA: haCount++; break;
        case ContractStatsOption.PacemakerOnly: pacemakeOnlyCount++; break;
        case ContractStatsOption.ProxyDownload: proxyCount++; break;
        case ContractStatsOption.SDS: sdsCount++; break;
        default: otherCount++;
      }
    }
    chartBars[ContractStatsOption.HA_SDS].push({ name: ContractStatsOption.HA_SDS, x: i + '', y: mixedCount });
    chartBars[ContractStatsOption.DRBDOnly].push({ name: ContractStatsOption.DRBDOnly, x: i + '', y: drbdCount });
    chartBars[ContractStatsOption.HA].push({ name: ContractStatsOption.HA, x: i + '', y: haCount });
    chartBars[ContractStatsOption.PacemakerOnly].push(
      { name: ContractStatsOption.PacemakerOnly, x: i + '', y: pacemakeOnlyCount });
    chartBars[ContractStatsOption.ProxyDownload].push(
      { name: ContractStatsOption.ProxyDownload, x: i + '', y: proxyCount });
    chartBars[ContractStatsOption.SDS].push({ name: ContractStatsOption.SDS, x: i + '', y: sdsCount });
  }

  const chartLegend = Object.keys(chartBars).map((cbKey) => ({ name: cbKey }));
  return (
    <Card>
      <CardTitle><Title headingLevel='h2'>Contracts issued per year</Title></CardTitle>
      <CardBody>
        <Chart
          containerComponent={<ChartVoronoiContainer labels={({ datum }) => `${datum.name}: ${datum.y}`} constrainToVisibleArea />}
          legendPosition='bottom-left'
          themeColor={ChartThemeColor.multiUnordered}
          legendData={chartLegend}
          legendAllowWrap
          padding={{ // Adjusted to accommodate legend
            bottom: 130,
            left: 50,
            right: 20,
            top: 20
          }}
          width={300}
          height={300}>
          <ChartAxis />
          <ChartAxis dependentAxis showGrid />
          <ChartStack>
            {Object.keys(chartBars).map((cbkey) => <ChartBar key={cbkey} data={chartBars[cbkey]} />)}
          </ChartStack>
        </Chart>
      </CardBody>
    </Card>
  )
}

interface ActiveContractsPerIssuerCardProps {
  contracts: ManagerContract[]
}

const ActiveContractsPerIssuerCard: React.FunctionComponent<ActiveContractsPerIssuerCardProps> =
  (props: ActiveContractsPerIssuerCardProps) => {
    const [filterEval, setFilterEval] = React.useState(false);

    const { isLoading, isError, data, error } = queryIssuers();

    if (isError || isLoading) {
      return <Spinner isSVG />
    }

    const issuerLookup = data.list.reduce((map: { [key: number]: string }, obj) => {
      map[obj.id] = obj.name;
      return map;
    }, {});

    let chartDataMap: { [key: string]: number } = {};
    for (const c of props.contracts) {
      if (filterEval && c.kind_id == 6) continue;
      const name = issuerLookup[c.issuer_id];
      if (name in chartDataMap) chartDataMap[name]++; else chartDataMap[name] = 1;
    }

    const chartData = Object.keys(chartDataMap)
      .map((key) => ({ 'x': key + ": " + chartDataMap[key], 'y': chartDataMap[key] }))
      .sort((a, b) => { return b.y - a.y });
    const chartLegend = chartData.map((l) => { return { name: l.x } });
    return (
      <Card>
        <CardTitle>
          <Title headingLevel='h2'>Active contracts by issuer&nbsp;
            {/* <Tooltip content="Filter eval contracts">
              <Switch aria-label="filter eval" onChange={(checked) => setFilterEval(checked)} isChecked={filterEval} />
            </Tooltip> */}
            <Popover
              headerContent="Legend"
              bodyContent={<ChartLegend
                data={chartLegend} orientation="vertical" themeColor={ChartThemeColor.multiOrdered} />}>
              <button
                type="button"
                aria-label="filter rules description"
                onClick={e => e.preventDefault()}
                className="pf-c-form__group-label-help"
              ><HelpIcon noVerticalAlign /></button>
            </Popover>
          </Title></CardTitle>
        <CardBody>
          <ChartPie
            constrainToVisibleArea
            data={chartData}
            legendPosition='bottom'
            legendAllowWrap
            legendOrientation='horizontal'
            // legendData={chartLegend}
            themeColor={ChartThemeColor.multiUnordered}
            // padding={{ // Adjusted to accommodate legend
            //   bottom: 130,
            //   left: 20,
            //   right: 20,
            //   top: 20
            // }}
            width={300}
            height={300}
          />
        </CardBody>
      </Card>
    )
  }

const Dashboard: React.FunctionComponent<{}> = () => {
  const ISSUED_PER_YEAR_START = "2017-12-31";
  const qryContractsIssuedPerYear = useQuery<ManagerContract[], string>(
    ["stats", "contracts", ISSUED_PER_YEAR_START, undefined, undefined, undefined, undefined],
    () => fetchStatsContracts(new Date(ISSUED_PER_YEAR_START)), { staleTime: 60 * 60 * 1000 });
  const qryContracts = useQuery<ManagerContract[], string>(
    ["stats", "contracts", TODAY, undefined, undefined, undefined, undefined],
    () => fetchStatsContracts(TODAY), { staleTime: 60 * 60 * 1000 });
  const { isLoading, isError, data, error } = queryContractKinds(false);
  const claims = getCurrentClaims();

  if (isError) {
    return (<PageSection />)
  }

  if (isLoading) {
    return (<Spinner />)
  }

  const contractKinds: Map<number, ContractKind> = data || new Map<number, ContractKind>();

  const contracts = qryContracts.data ? qryContracts.data : [];
  const contractsAll = qryContractsIssuedPerYear.data ? qryContractsIssuedPerYear.data : [];
  return (
    <PageSection>
      <Title headingLevel="h1" size="xl">Dashboard</Title>
      <Gallery hasGutter minWidths={{ md: '300px' }} maxWidths={{ md: '340px' }}>
        <GalleryItem><ContractKindStatsCard kinds={contractKinds} /></GalleryItem>
        <GalleryItem>
          {qryContracts.isLoading ? <Spinner isSVG /> : <ContractOptionsStatsCard contracts={contracts} />}
        </GalleryItem>
        <GalleryItem>
          {qryContracts.isLoading ? <Spinner isSVG /> : <SDSOptionStatsCard contracts={contracts} />}
        </GalleryItem>
        <GalleryItem>{qryContractsIssuedPerYear.isLoading ?
          <Spinner isSVG /> : <ContractsIssuedCard contracts={contractsAll} lastYears={5} />}
        </GalleryItem>
        {claims.role === "Admin" || claims.role === "Supporter" ?
          <GalleryItem>{qryContracts.isLoading ? <Spinner isSVG /> : <ActiveContractsPerIssuerCard contracts={contracts} />}
          </GalleryItem> : null}
        <GalleryItem>
          <DistributionsCard />
        </GalleryItem>
        <GalleryItem>
          <RegisteredNodesPerYearCard />
        </GalleryItem>
      </Gallery>
    </PageSection>
  )
}

export { Dashboard };
