import * as React from 'react';
import {
    PageSection,
    Title,
    Spinner,
    Button,
    Pagination,
    PaginationVariant,
    Toolbar,
    ToolbarItem,
    ToolbarContent,
    ToolbarGroup,
    Switch,
    SearchInput,
    TextInput,
    Alert,
    AlertActionCloseButton
} from '@patternfly/react-core';
import DownloadIcon from '@patternfly/react-icons/dist/esm/icons/download-icon';
import { Link, useNavigate } from 'react-router-dom';

import {
    TableComposable, Tbody, Th, Thead, Tr, Td, ThProps
} from '@patternfly/react-table';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { ManagerCustomer, CreateCustomerRequest, ContractOption } from 'linbit-api-fetcher';
import { apiPost, fetchCustomers } from './fetcher';
import * as styles from './lab.module.css';
import { AccountManagerSelect } from './form-components';
import { LdapNameResolver } from './components';
import { saveAs } from 'file-saver';
import { ContractOptionSelect } from './contract-components';
import { isValidRegex } from './util';

const Customers: React.FunctionComponent<{}> = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const [filterContractOptions, setFilterContractOptions] = React.useState<number[]>([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [filterAccManager, setFilterAccManager] = React.useState("");
    const [inputValue, setInputValue] = React.useState('');
    const [expiredContracts, setExpiredContracts] = React.useState(false);
    const [expiredOnly, setExpiredOnly] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [itemsPerPage, setItemsPerPage] = React.useState(20);

    const [newCustomerName, setNewCustomerName] = React.useState('');

    const [activeSortIndex, setActiveSortIndex] = React.useState<number>(0);

    // Sort direction of the currently sorted column
    const [activeSortDirection, setActiveSortDirection] = React.useState<'asc' | 'desc'>('asc');

    const ALPHAS = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M",
        "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

    const getSortParams = (columnIndex: number): ThProps['sort'] => ({
        sortBy: {
            index: activeSortIndex,
            direction: activeSortDirection
        },
        onSort: (_event, index, direction) => {
            setActiveSortIndex(index);
            setActiveSortDirection(direction);
        },
        columnIndex
    });


    /** callback for updating the inputValue state in this component so that the input can be controlled */
    const handleSearchInputChange = (_event: React.FormEvent<HTMLInputElement>, value: string) => {
        setInputValue(value);
        setCurrentPage(1);
    };

    /** show the input clearing button only when the input is not empty */
    const showClearButton = inputValue;

    /** callback for clearing the text input */
    const clearSearchInput = () => {
        setInputValue('');
    };

    const handleExpiredCheckBox = (checked: boolean, _event: React.FormEvent<HTMLInputElement>) => {
        if (!checked) {
            setExpiredOnly(false);
        }
        setExpiredContracts(checked);
    }

    const handleExpiredOnlySwitch = (checked: boolean, _event: React.FormEvent<HTMLInputElement>) => {
        if (checked) {
            setExpiredContracts(true);
        }
        setExpiredOnly(checked);
    }

    const onChangeAccMgrFilter = (val: string) => {
        setFilterAccManager(val);
    }

    const onCreateCustomer = () => {
        let createReq: CreateCustomerRequest = {
            name: newCustomerName.trim(),
        }
        apiPost<ManagerCustomer>('/manager/customers', createReq)
            .then((resp) => {
                queryClient.invalidateQueries(['customers']);
                navigate('/customers/' + resp.id);
                setErrorMessage("");
            }).catch((reason) => {
                console.error("Error creating customer: " + reason);
                setErrorMessage("Error creating customer: " + reason);
            });
    }

    function csvField(str?: string | null) {
        if (str)
            return '"' + str.replaceAll('"', '""') + '"';
        return "";
    }

    const onExportCSV = () => {
        const filtered = filterAndSortCustomers(
            data ? data : [], inputValue, filterAccManager, activeSortIndex, activeSortDirection);

        const csv: string[] = ["Name,Highest Support Level,Expiry date,Issuer,Account Manager"];
        const csvBlob = new Blob();
        for (const c of filtered) {
            const line = [
                csvField(c.name),
                csvField(c.highest_support_level),
                csvField(c.latest_expiry_date),
                csvField(c.issuers.length == 1 ? c.issuers[0] : c.issuers.length === 0 ? "" : "<more than one>"),
                csvField(c.account_managers.length == 1 ? c.account_managers[0] : c.account_managers.length === 0 ? "" : "<more than one>"),
            ];
            csv.push(line.join(","))
        }
        saveAs(new Blob([csv.join("\n")], { type: "text/csv" }), "customers.csv");
    }

    const onContractOptionsSelected = (contractOptions_: ContractOption[]) => {
        setFilterContractOptions(contractOptions_.map(co => co.id));
    }

    const CustomerToolbar = (
        <Toolbar>
            <ToolbarContent>
                <ToolbarItem>
                    <SearchInput
                        placeholder="customer regex"
                        style={{ width: "180px" }}
                        value={inputValue}
                        onChange={handleSearchInputChange}
                        onClear={clearSearchInput} />
                </ToolbarItem>
                <ToolbarItem>
                    <Switch
                        label="expired?"
                        isChecked={expiredContracts}
                        onChange={handleExpiredCheckBox}
                        aria-label="expaired switch"
                        id="expired-switch"
                        isReversed />
                </ToolbarItem>
                <ToolbarItem>
                    <Switch
                        label="expired only?"
                        isChecked={expiredOnly}
                        onChange={handleExpiredOnlySwitch}
                        aria-label="expaired only switch"
                        id="expired-only-switch"
                        isReversed />
                </ToolbarItem>
                <ToolbarItem>
                    <AccountManagerSelect
                        noneLabel="<All-Account-Manager>"
                        accountManager={filterAccManager}
                        onChange={onChangeAccMgrFilter} />
                </ToolbarItem>
                <ToolbarItem>
                    <ContractOptionSelect
                        id='contract-options'
                        variant='checkbox'
                        placeholderText='Filter contract options'
                        selected={filterContractOptions}
                        onContractOptionSelected={onContractOptionsSelected} />
                </ToolbarItem>
                <ToolbarItem>
                    <Button variant='control' onClick={onExportCSV}><DownloadIcon /> .csv</Button>
                </ToolbarItem>
            </ToolbarContent>
        </Toolbar>
    );

    const ToolbarRow2 = (
        <Toolbar>
            <ToolbarContent>
                <ToolbarItem>
                    {ALPHAS.map((c) => <Button
                        key={c}
                        isSmall
                        style={{
                            fontFamily: "RedHatMono",
                            padding: "3px 9px",
                            marginRight: "2px"
                        }}
                        onClick={() => setInputValue("^" + c)}>{c}</Button>)}
                </ToolbarItem>
                <ToolbarGroup alignment={{ default: "alignRight" }}>
                    <ToolbarItem style={{ marginRight: 0 }}>
                        <TextInput
                            value={newCustomerName}
                            placeholder="new customer"
                            size={15}
                            type="text"
                            onChange={(val) => setNewCustomerName(val)}
                            aria-label="new customer nambe" />
                    </ToolbarItem>
                    <ToolbarItem>
                        <Button variant='primary' onClick={onCreateCustomer} isDisabled={!newCustomerName.trim()}>Create</Button>
                    </ToolbarItem>
                </ToolbarGroup>
            </ToolbarContent>
        </Toolbar>
    );

    function firstOrMoreThanOne(arr: string[]) {
        return arr.length == 1 ? arr[0] : arr.length === 0 ? "" : "<more than one>"
    }

    function renderCustomerData(customerData: ManagerCustomer[]) {
        return customerData.map((cs) => {
            const link = "/customers/" + cs.id;
            return (
                <Tr key={cs.id}>
                    <Td><Link id={"customer-" + cs.id} to={link}>{cs.name}</Link></Td>
                    <Td>{cs.highest_support_level}</Td>
                    <Td style={{ fontFamily: "RedHatMono" }}>{cs.latest_expiry_date}</Td>
                    <Td>{cs.issuers.length == 1 ? cs.issuers[0] : cs.issuers.length === 0 ? "" : "<more than one>"}</Td>
                    <Td>{cs.account_managers.length == 1 ?
                        <LdapNameResolver ldap_uid={cs.account_managers[0]} /> :
                        cs.account_managers.length === 0 ? "" : "<more than one>"}</Td>
                </Tr>
            )
        })
    }

    const onSetPage = (_event: any, pageNumber: number) => {
        setCurrentPage(pageNumber);
    }

    const onPerPageSelect = (_event: any, itemsPerPage: number, newPage: number) => {
        setItemsPerPage(itemsPerPage);
        setCurrentPage(newPage);
    }

    function filterAndSortCustomers(
        customers: ManagerCustomer[],
        searchInput: string,
        accManager: string,
        sortIndex: number,
        sortDir: string) {
        const re = isValidRegex(searchInput) ? new RegExp(searchInput, "i") : new RegExp("", "i");
        const filtered = customers.filter((v) => {
            if (accManager !== "") {
                return v.account_managers.find((a) => a === accManager) && v.name.search(re) >= 0;
            }
            return v.name.search(re) >= 0;
        }).sort((a, b) => {
            switch (sortIndex) {
                case 1: {
                    const aSupp = a.highest_support_level ? a.highest_support_level : "";
                    const bSupp = b.highest_support_level ? b.highest_support_level : "";
                    return sortDir === 'asc' ? aSupp.localeCompare(bSupp) : bSupp.localeCompare(aSupp);
                }
                case 2: {
                    const aDate = a.latest_expiry_date ? Date.parse(a.latest_expiry_date) : 0;
                    const bDate = b.latest_expiry_date ? Date.parse(b.latest_expiry_date) : 0;
                    return sortDir === 'asc' ? aDate - bDate : bDate - aDate;
                }
                case 3: {
                    const aIssuer = firstOrMoreThanOne(a.issuers);
                    const bIssuer = firstOrMoreThanOne(b.issuers);
                    return sortDir === 'asc' ? aIssuer.localeCompare(bIssuer) : bIssuer.localeCompare(aIssuer);
                }
                case 4: {
                    const aAccMgr = firstOrMoreThanOne(a.account_managers);
                    const bAccMgr = firstOrMoreThanOne(b.account_managers);
                    return sortDir === 'asc' ? aAccMgr.localeCompare(bAccMgr) : bAccMgr.localeCompare(aAccMgr);
                }
                default: {
                    return sortDir === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
                }
            }
        });
        return filtered;
    }



    const { isLoading, isError, data, error } =
        useQuery<ManagerCustomer[], string>(['customers', expiredContracts, filterContractOptions, expiredOnly], () =>
            fetchCustomers(expiredContracts, filterContractOptions, expiredOnly))

    if (isError) {
        return <Alert variant='danger' title={error}></Alert>
    }

    let filtered = filterAndSortCustomers(
        data ? data : [], inputValue, filterAccManager, activeSortIndex, activeSortDirection);
    const count = filtered.length;
    let start = (currentPage - 1) * itemsPerPage;
    filtered = filtered.slice(start, start + itemsPerPage);

    return (
        <PageSection>
            <Title headingLevel="h1" size="xl">List of customers</Title>
            {errorMessage &&
                <Alert
                    title={errorMessage}
                    actionClose={<AlertActionCloseButton onClose={() => setErrorMessage('')} />} />}
            {CustomerToolbar}
            {ToolbarRow2}
            {isLoading ?
                <Spinner isSVG /> :
                <TableComposable
                    variant='compact'
                    className={styles.smalltable}
                >
                    <Thead>
                        <Tr>
                            <Th width={45} sort={getSortParams(0)}>Name</Th>
                            <Th width={10} sort={getSortParams(1)}>Highest Support Level</Th>
                            <Th width={10} sort={getSortParams(2)}>Expiry date</Th>
                            <Th width={20} sort={getSortParams(3)}>Issuer</Th>
                            <Th width={15} sort={getSortParams(4)}>Account Mgr</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {renderCustomerData(filtered)}
                    </Tbody>
                </TableComposable>
            }
            <Pagination
                itemCount={count}
                widgetId="pagination-customers-menu-bottom"
                perPage={itemsPerPage}
                page={currentPage}
                onSetPage={onSetPage}
                onPerPageSelect={onPerPageSelect}
                variant={PaginationVariant.bottom}
                perPageOptions={[
                    { title: '10', value: 10 }, { title: '20', value: 20 },
                    { title: '50', value: 50 }, { title: '100', value: 100 },
                    { title: 'All', value: count }
                ]}
            />
        </PageSection>
    )
}

export { Customers };
