import * as React from 'react';
import {
    Alert, AlertActionCloseButton, AlertVariant, Button, DatePicker, isValidDate, PageSection, Spinner, Switch, Toolbar,
    ToolbarContent, ToolbarItem, Tooltip
} from '@patternfly/react-core';
import { Link } from 'react-router-dom';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import { TableComposable, Tbody, Td, Th, Thead, ThProps, Tr } from '@patternfly/react-table';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import DownloadIcon from '@patternfly/react-icons/dist/esm/icons/download-icon';
import MailIcon from '@patternfly/react-icons/dist/esm/icons/mail-bulk-icon';
import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon';

import {
    apiDelete, apiGet, apiGetBlob, apiPost, getCurrentClaims,
    isReadOnlyCustomer, queryClusters, queryCustomerContracts, queryLicTypes
} from '../fetcher';
import {
    Cluster, CreateClusterLicenseRequest, License,
    LicensesResponse, LicenseTypeResponse, ManagerContract
} from 'linbit-api-fetcher';
import * as styles from '../lab.module.css';
import { setUTC } from '../formatter';

interface LicenseTableProps {
    customerId: number;
}

const TOMORROW = new Date(Date.now() + 86400000);

const LicenseTable: React.FunctionComponent<LicenseTableProps> = (props: LicenseTableProps) => {
    const queryClient = useQueryClient();
    const claims = getCurrentClaims();

    type CheckMap = { [key: number]: boolean };
    type AlertStatus = { message?: JSX.Element, type: AlertVariant };

    const [statusMessage, setStatusMessage] = React.useState<AlertStatus>({ type: AlertVariant.default });
    const [renewDate, setRenewDate] = React.useState(new Date());
    const [showExpired, setShowExpired] = React.useState(false);
    const [checkMap, setCheckMap] = React.useState<CheckMap>({});

    const [activeSortIndex, setActiveSortIndex] = React.useState<number>(2);
    // Sort direction of the currently sorted column
    const [activeSortDirection, setActiveSortDirection] = React.useState<'asc' | 'desc'>('asc');

    async function fetchClusterLicenses(clusterId: number): Promise<License[]> {
        let uri = '/manager/customers/' + props.customerId + '/clusters/' + clusterId + '/licenses';
        return apiGet<LicensesResponse>(uri)
            .then((resp) => resp.list);
    }

    async function downloadLicense(clusterId: number, licenseId: number, fileName: string) {
        let uri = '/manager/customers/' + props.customerId + '/clusters/' + clusterId + '/licenses/' + licenseId + '/file';
        await apiGetBlob(uri)
            .then(blob => saveAs(blob, fileName))
            .catch((err) => {
                let errMsg = err.toString();
                let answer = JSON.parse(err);
                if (answer) {
                    errMsg = "Error(" + answer.error.code + "): " + answer.error.message;
                }
                setStatusMessage({ message: <p>{errMsg}</p>, type: AlertVariant.danger });
            });
    }

    function removeLicense(clusterId: number, licenseId: number) {
        let uri = '/manager/customers/' + props.customerId + '/clusters/' + clusterId + '/licenses/' + licenseId;
        apiDelete<String>(uri)
            .then((_resp) => queryClient.invalidateQueries(['licenses', clusterId]))
            .catch((reason) => setStatusMessage({ message: <p>{reason}</p>, type: AlertVariant.danger }));
    }

    const getSortParams = (columnIndex: number): ThProps['sort'] => ({
        sortBy: {
            index: activeSortIndex,
            direction: activeSortDirection
        },
        onSort: (_event, index, direction) => {
            setActiveSortIndex(index);
            setActiveSortDirection(direction);
        },
        columnIndex
    });

    const onCheckAll = (checked: boolean) => {
        let ckMap: CheckMap = {}
        for (const l of licenses) {
            ckMap[l.id] = checked;
        }
        setCheckMap(ckMap);
    }

    const allChecked = () => {
        const checked = Object.entries(checkMap).filter((v) => v[1]);
        return checked.length == licenses.length;
    }

    const onChangeLicenseChecked = (licenseId: number, checked: boolean) => {
        let ckMap: CheckMap = { ...checkMap };
        ckMap[licenseId] = checked;
        setCheckMap(ckMap);
    }

    const onChangeRenewDate = (_event: React.FormEvent<HTMLInputElement>, str: string, date?: Date | undefined) => {
        if (date && isValidDate(date)) {
            const newD = new Date();
            setUTC(newD, date.getFullYear(), date.getMonth(), date.getDate());
            setRenewDate(newD);
        }
    }

    function selectedLicenses() {
        const rLics: License[] = Object.entries(checkMap)
            .filter((v) => v[1] && licenses.find(l => l.id === +v[0]))
            .map((v) => {
                const lic = licenses.find(l => l.id === +v[0]);
                return lic ? lic : {
                    id: 0, cluster_id: 0, date_issued: "illegal", owner: "", license_type_id: 0,
                    integer_values: {}, bool_values: {}, string_values: {}
                }
            });
        return rLics;
    }

    const renewLicenses = () => {
        setStatusMessage({ type: AlertVariant.default });
        const rLics = selectedLicenses();

        const oldestExp = rLics.sort((a, b) => {
            const aDate = a.expiration_date ? Date.parse(a.expiration_date) : 0;
            const bDate = b.expiration_date ? Date.parse(b.expiration_date) : 0;
            return aDate - bDate;
        })[0].expiration_date;
        if (oldestExp) {
            const oldestExpDate = new Date(Date.parse(oldestExp));
            if (oldestExpDate >= renewDate) {
                setStatusMessage({
                    message: <p>Renew date is sooner than oldest expiration date '{oldestExp}'</p>,
                    type: AlertVariant.danger
                });
                return;
            }
        }

        let posts = [];
        for (const lic of rLics) {
            let createreq: CreateClusterLicenseRequest = {
                license_type_id: lic.license_type_id,
                bool_values: Object.keys(lic.bool_values).map((key, index) => {
                    return lic.bool_values[+key]
                }),
                integer_values: [],
                string_values: [],
                expiration_date: renewDate.toISOString().slice(0, 10)
            };
            console.log(createreq);
            posts.push(apiPost<License>('/manager/customers/' + props.customerId + '/clusters/' + lic.cluster_id + "/licenses", createreq));
        }

        Promise.all(posts)
            .then((values) => {
                setCheckMap({});
                let newChecked: CheckMap = {};
                for (const newLic of values) {
                    queryClient.invalidateQueries(['licenses', newLic.cluster_id]);
                    newChecked[newLic.id] = true;
                }
                setCheckMap(newChecked);
                setStatusMessage({ message: <p>{values.length} Licenses created and selected.</p>, type: AlertVariant.success });
            }).catch((reason) => {
                setStatusMessage({ message: <p>{reason.toString()}</p>, type: AlertVariant.danger });
            });
    }

    const onBulkDownload = () => {
        setStatusMessage({ type: AlertVariant.default });
        const lics = selectedLicenses();

        let downloads = [];
        const clustersLookUp: (Cluster | undefined)[] = [];
        for (const lic of lics) {
            let uri = '/manager/customers/' + props.customerId + '/clusters/' + lic.cluster_id + '/licenses/' + lic.id + '/file';
            downloads.push(apiGetBlob(uri));
            clustersLookUp.push(clusters.find((c) => c.id == lic.cluster_id));
        }

        Promise.allSettled(downloads)
            .then((results) => {
                const zip = JSZip();
                let fullerr: string[] = [];
                results.forEach((result, index) => {
                    if (result.status === 'fulfilled') {
                        const licBlob = result.value;
                        const lic = lics[index];
                        const licType = licTypes.licenses[lic.license_type_id];
                        const fileName = licType.name.startsWith("RDMA") ? "drbd-transport-rdma.license" : "drbd-proxy.license";
                        const cluster = clustersLookUp[index];
                        if (cluster) {
                            cluster.nodes.forEach((n) => {
                                const folderName = n.hostname ? n.hostname : n.url_hash;
                                const folder = zip.folder(folderName);
                                if (folder) {
                                    folder.file(fileName, licBlob);
                                }
                            });
                        }
                    } else if (result.status === 'rejected') {
                        let errMsg = result.reason;
                        const answer = JSON.parse(result.reason);
                        const cluster = clustersLookUp[index];
                        if (answer) {
                            fullerr.push("Error(" + answer.error.code + "): " + answer.error.message + " on cluster " + cluster?.id);
                        }
                    }
                });
                zip.generateAsync({ type: "blob" })
                    .then((blob) => {
                        saveAs(blob, "licenses.zip");
                        if (fullerr.length > 0) {
                            setStatusMessage({ message: <ul>{fullerr.map((e) => <li>{e}</li>)}</ul>, type: AlertVariant.danger });
                        }
                    });
            }).catch((err) => {
                let errMsg = err.toString();
                let answer = JSON.parse(err);
                if (answer) {
                    errMsg = "Error(" + answer.error.code + "): " + answer.error.message;
                }
                setStatusMessage({ message: <p>{errMsg}</p>, type: AlertVariant.danger });
            });
    }

    const onBulkDelete = () => {
        const lics = selectedLicenses();

        let message = "Really delete the following licenses?\n\n" +
            "Type                     - Date Issued - Expiration\n"
        for (const lic of lics) {
            let licType = licTypes.licenses[lic.license_type_id];
            message += `${licType.name} - ${lic.date_issued} - ${lic.expiration_date}\n`;
        }

        if (confirm(message)) {
            for (const lic of lics) {
                removeLicense(lic.cluster_id, lic.id);
            }
            setCheckMap({});
            setStatusMessage({ message: <p>{lics.length} Licenses deleted.</p>, type: AlertVariant.success });
        }
    }

    function getActiveContractIds(contractActive: ManagerContract[], clusters: Cluster[], lic: License) {
        const cluster = clusters.find((c) => c.id === lic.cluster_id);
        const nodes = cluster ? cluster.nodes : [];
        return nodes
            .map((n) => n.current_contract)
            .filter((v, i, a) => v !== null && a.indexOf(v) === i) // unique
            .filter((c_id) => contractActive.find((contract) => contract.id === c_id)); // show only active contracts
    }

    function subNumSort(by: string, a: number | null | undefined, b: number | null | undefined) {
        const x = a ? a : 0;
        const y = b ? b : 0;
        return activeSortDirection === 'asc' ? x - y : y - x;
    }

    function renderLicenseRows(
        licTypes: LicenseTypeResponse, contractActive: ManagerContract[], clusters: Cluster[], licenses: License[]) {
        return licenses.sort((a, b) => {
            if (activeSortIndex !== null) {
                if (activeSortIndex === 2) {
                    const aDate = a.expiration_date ? Date.parse(a.expiration_date) : 999999999999999;
                    const bDate = b.expiration_date ? Date.parse(b.expiration_date) : 999999999999999;
                    return activeSortDirection === 'asc' ? aDate - bDate : bDate - aDate;
                }
                if (activeSortIndex === 3) {
                    const aDate = a.invoice_issued ? Date.parse(a.invoice_issued) : 0;
                    const bDate = b.invoice_issued ? Date.parse(b.invoice_issued) : 0;
                    return activeSortDirection === 'asc' ? aDate - bDate : bDate - aDate;
                }
                if (activeSortIndex === 4) {
                    return activeSortDirection === 'asc' ? a.cluster_id - b.cluster_id : b.cluster_id - a.cluster_id;
                }
                if (activeSortIndex === 5) {
                    const aDate = Date.parse(a.date_issued);
                    const bDate = Date.parse(b.date_issued);
                    return activeSortDirection === 'asc' ? aDate - bDate : bDate - aDate;
                }
                if (activeSortIndex === 6) {
                    const aContracts = getActiveContractIds(contractActive, clusters, a).sort((x, y) => subNumSort(activeSortDirection, x, y));
                    const bContracts = getActiveContractIds(contractActive, clusters, b).sort((x, y) => subNumSort(activeSortDirection, x, y));
                    const aFirst = aContracts.length > 0 ? aContracts[0] ? aContracts[0] : 0 : 0;
                    const bFirst = bContracts.length > 0 ? bContracts[0] ? bContracts[0] : 0 : 0;
                    return activeSortDirection === 'asc' ? aFirst - bFirst : bFirst - aFirst;
                }
            }
            return activeSortDirection === 'asc' ? a.license_type_id - b.license_type_id : b.license_type_id - a.license_type_id;
        }).map((l) => {
            const isExpired = l.expiration_date ? Date.parse(l.expiration_date) < Date.now() : false;
            const licType = licTypes.licenses[l.license_type_id];
            const nodes_contracts_active = getActiveContractIds(contractActive, clusters, l);
            const options = Object.keys(licType.options).map((key) => {
                const licOpt = licType.options[key];
                const licOptName = licOpt.name;
                let licOptValue = "not set";
                switch (licOpt.data_type.toLowerCase()) {
                    case "boolean": licOptValue = licOpt.id in l.bool_values ? l.bool_values[licOpt.id].value + "" : "not set"; break;
                    case "integer": licOptValue = licOpt.id in l.integer_values ? l.integer_values[licOpt.id].value + "" : "not set"; break;
                    case "string": licOptValue = licOpt.id in l.string_values ? l.string_values[licOpt.id].value : "not set"; break;
                }
                return { "name": licOptName, "value": licOptValue }
            })
            return (
                <Tr key={l.id} {...(isExpired ? { style: { backgroundColor: "#ff8383" } } : {})}>
                    <Td
                        select={{
                            rowIndex: l.id,
                            onSelect: (_event, isSelecting) => onChangeLicenseChecked(l.id, isSelecting),
                            isSelected: l.id in checkMap && checkMap[l.id],
                        }}
                    />
                    <Td>{licTypes.licenses[l.license_type_id].name}</Td>
                    <Td><Link to={"/customers/" + props.customerId + "?tab=1&filter=" + encodeURIComponent("cluster=" + l.cluster_id)}>{l.cluster_id}</Link></Td>
                    <Td>{nodes_contracts_active.map((c) => <React.Fragment key={c}><Link to={`?tab=0#ct-${c}`}>{c}</Link><br /></React.Fragment>)}</Td>
                    <Td>{options.map((opt) => { return <p key={opt.name}>{opt.name}: {opt.value}</p> })}</Td>
                    <Td style={{ fontFamily: "RedHatMono" }}>{l.date_issued}</Td>
                    <Td style={{ fontFamily: "RedHatMono" }}>{l.expiration_date ? l.expiration_date : "Unlimited"}</Td>
                    <Td style={{ fontFamily: "RedHatMono" }}>{l.invoice_issued ? l.invoice_issued : "none yet"}</Td>
                    <Td textCenter style={{ whiteSpace: "nowrap" }}>
                        <Button
                            variant='plain'
                            className={styles.smallpadding}
                            isSmall
                            onClick={() => downloadLicense(
                                l.cluster_id, l.id, licType.name.startsWith("RDMA") ? "drbd-transport-rdma.license" : "drbd-proxy.license")}
                            isDisabled={isReadOnlyCustomer(claims, [])}>
                            <Tooltip content="Download license"><DownloadIcon /></Tooltip></Button>
                        <Button variant='plain' className={styles.smallpadding} isSmall
                            onClick={() => alert("not implemented yet")}>
                            <Tooltip content="Send license"><MailIcon /></Tooltip></Button>
                        <Button variant='plain' className={styles.smallpadding} isSmall
                            onClick={() => { if (confirm("Really delete license?")) removeLicense(l.cluster_id, l.id) }}
                            isDisabled={isReadOnlyCustomer(claims, [])}>
                            <Tooltip content="Remove license"><TrashIcon /></Tooltip></Button>
                    </Td>
                </Tr>
            )
        })
    }

    const licTypesQuery = queryLicTypes();
    const clusterQR = queryClusters(props.customerId);
    const contractsQR = queryCustomerContracts(props.customerId, false);

    const clusters = clusterQR.data ? clusterQR.data.list : [];
    const contractsActive = contractsQR.data ? contractsQR.data : [];

    const licenseQueries = useQueries({
        queries: clusters.map(cluster => {
            return {
                queryKey: ['licenses', cluster.id],
                queryFn: () => fetchClusterLicenses(cluster.id),
            }
        })
    });

    if (clusterQR.isError || licTypesQuery.isError || contractsQR.isError) {
        return (<span>Error: {licTypesQuery.error} {clusterQR.error} {contractsQR.error}</span>)
    }

    if (clusterQR.isLoading || licTypesQuery.isLoading || contractsQR.isLoading) {
        return (<Spinner isSVG />)
    }

    for (const qry of licenseQueries) {
        if (qry.isLoading) {
            return (<Spinner isSVG />);
        }
    }

    let licenses: License[] = [];

    for (const qry of licenseQueries) {
        if (qry.data) {
            licenses = licenses.concat(qry.data);
        }
    }

    const licTypes = licTypesQuery.data ? licTypesQuery.data : { licenses: {} };

    licenses = licenses.filter(l => {
        const isExpired = l.expiration_date ? Date.parse(l.expiration_date) < Date.now() : false;
        return showExpired || !isExpired;
    })

    return (
        <React.Fragment>
            {statusMessage.message && <Alert
                title={statusMessage.message}
                variant={statusMessage.type}
                actionClose={<AlertActionCloseButton onClose={() => setStatusMessage({ type: AlertVariant.default })} />} />
            }
            <Toolbar>
                <ToolbarContent>
                    <ToolbarItem>
                        <Switch
                            label="Show expired?"
                            isChecked={showExpired}
                            onChange={(checked) => setShowExpired(checked)}
                            aria-label="expaired switch"
                            id="expired-switch"
                            hasCheckIcon
                            isReversed />
                    </ToolbarItem>
                    <ToolbarItem>
                        Renew selected licenses to
                        <DatePicker
                            id='issued-from-date'
                            appendTo={document.body}
                            onChange={onChangeRenewDate}
                            value={renewDate.toISOString().slice(0, 10)} />
                        <Button
                            onClick={renewLicenses}
                            isDisabled={renewDate <= TOMORROW || Object.entries(checkMap).every((v) => !v[1])}>Renew</Button>
                    </ToolbarItem>
                    <ToolbarItem>
                        <Tooltip content='Download selected'>
                            <Button aria-label='Bulk download' variant='control'
                                onClick={onBulkDownload}
                                isDisabled={Object.entries(checkMap).every((v) => !v[1])}><DownloadIcon /></Button>
                        </Tooltip>
                    </ToolbarItem>
                    <ToolbarItem>
                        <Tooltip content='Delete selected'>
                            <Button aria-label='Bulk delete' variant='control'
                                onClick={onBulkDelete}
                                isDisabled={Object.entries(checkMap).every((v) => !v[1])}><TrashIcon /></Button>
                        </Tooltip>
                    </ToolbarItem>
                </ToolbarContent>
            </Toolbar>
            <TableComposable
                variant='compact'
                className={styles.smalltable}
            >
                <Thead>
                    <Tr>
                        <Th select={{
                            onSelect: (_event, isSelecting) => onCheckAll(isSelecting),
                            isSelected: allChecked()
                        }}
                        />
                        <Th width={25} sort={getSortParams(0)}>License Type</Th>
                        <Th sort={getSortParams(4)}>Cluster#</Th>
                        <Th sort={getSortParams(6)}>Contract(s)</Th>
                        <Th>Options</Th>
                        <Th sort={getSortParams(5)}>Issued</Th>
                        <Th sort={getSortParams(2)}>Expiration</Th>
                        <Th sort={getSortParams(3)}>Invoice</Th>
                        <Th width={10}>Action</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {renderLicenseRows(licTypes, contractsActive, clusters, licenses)}
                </Tbody>
            </TableComposable>
        </React.Fragment>
    )
}

interface LicenseViewProps {
    customerId: number,
}

const LicenseView: React.FunctionComponent<LicenseViewProps> = (props: LicenseViewProps) => {
    const [errorMessage, setErrorMessage] = React.useState("");

    return (
        <PageSection>
            {errorMessage && <Alert title={errorMessage} variant='danger' />}
            <LicenseTable customerId={props.customerId} />
        </PageSection>
    )
}

export { LicenseView }
