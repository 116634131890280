{
    "header": "Linbit contract manager - Changelog",
    "versions": [
        {
            "version": "1.9.1",
            "date": "2024-08-21",
            "added": [
            ],
            "changed": [
                "contract-edit: show invoice field only for Supporters"
            ],
            "fixed": [
            ]
        },
        {
            "version": "1.9.0",
            "date": "2024-08-14",
            "added": [
                "contract: added invoiced field, indicating if a contract was already invoiced"
            ],
            "changed": [
            ],
            "fixed": [
            ]
        },
        {
            "version": "1.8.0",
            "date": "2024-05-14",
            "added": [
                "dashboard: SDS contract pie-chart"
            ],
            "changed": [
                "contract-options: show id"
            ],
            "fixed": [
                "proxy-license date selection was one day off"
            ]
        },
        {
            "version": "1.7.2",
            "date": "2023-11-28",
            "added": [
            ],
            "changed": [
                "welcome-mail: change ticket mail to tickets@linbit.com"
            ],
            "fixed": [
            ]
        },
        {
            "version": "1.7.1",
            "date": "2023-08-24",
            "added": [
            ],
            "changed": [
            ],
            "fixed": [
                "tickets: use correct otrs source link"
            ]
        },
        {
            "version": "1.7.0",
            "date": "2023-08-08",
            "added": [
                "reports: add companies and contacts"
            ],
            "changed": [
            ],
            "fixed": [
            ]
        },
        {
            "version": "1.6.0",
            "date": "2023-06-22",
            "added": [
                "clusters: add delete all clusters button",
                "clusters: filter for contract expired clusters",
                "clusters: show node access info"
            ],
            "changed": [
                "customer: only show delete button if no contracts AND clusters"
            ],
            "fixed": [
            ]
        },
        {
            "version": "1.5.3",
            "date": "2023-06-06",
            "added": [
            ],
            "changed": [
            ],
            "fixed": [
                "edit-contract: payment date one day off, if entered first time"
            ]
        },
        {
            "version": "1.5.2",
            "date": "2023-05-19",
            "added": [
            ],
            "changed": [
                "edit-contract: sort contract option in selection"
            ],
            "fixed": [
            ]
        },
        {
            "version": "1.5.1",
            "date": "2023-05-11",
            "added": [
            ],
            "changed": [
                "renew contract: now correctly moves node assignements too"
            ],
            "fixed": [
            ]
        },
        {
            "version": "1.5.0",
            "date": "2023-03-31",
            "added": [
                "Contracts can now be moved to other customers"
            ],
            "changed": [
                "updated patternfly dependencies"
            ],
            "fixed": [
                "Tables never wrapped on mobile/responsive devices"
            ]
        },
        {
            "version": "1.4.0",
            "date": "2023-03-07",
            "added": [
                "customers: option to only show expired customers with their last contract date"
            ],
            "changed": [
                "updated to react 18.2 and latest patternfly",
                "Non may_issue_license users can now few clusters",
                "Drbd-proxy-3 is now the default no license option"
            ],
            "fixed": [
                "Fixed missing `key` in LicenceTable component"
            ]
        },
        {
            "version": "1.3.5",
            "date": "2022-12-21",
            "added": [
            ],
            "changed": [
                "Contacts: sort table by email"
            ],
            "fixed": [
                "Fix crashes on invalid regexp entered"
            ]
        },
        {
            "version": "1.3.4",
            "date": "2022-11-17",
            "added": [
            ],
            "changed": [
                "cluster-tab: allow to search for mac/ip/ibguid"
            ],
            "fixed": [
            ]
        },
        {
            "version": "1.3.3",
            "date": "2022-11-10",
            "added": [
                "license-tab: Add current active contracts column to license table"
            ],
            "changed": [
            ],
            "fixed": [
            ]
        },
        {
            "version": "1.3.2",
            "date": "2022-10-25",
            "added": [
            ],
            "changed": [
                "expiring-licenses: instead of days past/coming, you now can set exact dates and switch months"
            ],
            "fixed": [
                "registered-nodes: fix incorrect datepicker date setting"
            ]
        },
        {
            "version": "1.3.1",
            "date": "2022-10-21",
            "added": [
            ],
            "changed": [
            ],
            "fixed": [
                "cluster-node-edit: Check for no node set wasn't correctly for disable save"
            ]
        },
        {
            "version": "1.3.0",
            "date": "2022-10-19",
            "added": [
                "Customer: Licenses-tab with bulk licenses operations"
            ],
            "changed": [
                "ContractIdSelect: Improve option ui and show expired",
                "Cluster-node-edit: Only allow save if something changed",
                "logout: Reset navigation url"
            ],
            "fixed": [
            ]
        },
        {
            "version": "1.2.0",
            "date": "2022-10-06",
            "added": [
                "customers: Allow filtering by contract-options",
                "reports: Add registered nodes page (Admin/Supporter)"
            ],
            "changed": [
            ],
            "fixed": [
                "licenses: Fix filename for RDMA licenses"
            ]
        },
        {
            "version": "1.1.1",
            "date": "2022-10-04",
            "added": [
                "Add search contract id input in the header toolbar"
            ],
            "changed": [
                "Do not retry on fetch errors",
                "dashboard: Changed stale time for registered nodes per year",
                "dashboard: Changed heading size for node distris card"
            ],
            "fixed": [
                "edit-contract: correctly initialize contract-kind from allowed contract-kinds"
            ]
        },
        {
            "version": "1.1.0",
            "date": "2022-09-23",
            "added": [
                "customers: Add CSV download button, to download current table as CSV",
                "edit-contract: checkbox to link supportUntil and downloadUntil dates",
                "dashboard: Add ActiveContractPerIssuerCard (for admins/supporter)",
                "dashboard: Add registered nodes distributions",
                "dashboard: Add registered nodes per year",
                "contracts: show origin contract id in contract tables"
            ],
            "changed": [
                "edit-contract: Rename Cluster-Tab to 'Sales units'"
            ],
            "fixed": [
                "contract-edit: showing unrestricted contract options for issuers",
                "contract-edit: showing unrestricted contract kinds for issuers",
                "Fixed incorrect, 'is contract expired' condition (was wrong if expired today)",
                "contracts-view: fix link to expired contracts"
            ]
        },
        {
            "version": "1.0.8",
            "date": "2022-09-19",
            "added": [
                "dashboard: Added contracts issued per year statistic card",
                "dashboard: Added contract-options statistic card",
                "clusters tab: Allow filtering by clusterId, contractId, node-hash or nodename.",
                "contract page: For direct linking on contract id e.g: https://contract.linbit.com/contracts/43",
                "contract-view-table: Contract id has now a link to customer->contract",
                "changelog page: Added a changelog page to see a version changelog.",
                "contract-edit: Restrict shown contract-kinds"
            ],
            "changed": [
                "Updated contract details email template.",
                "customer page: Use box style tabs."
            ]
        },
        {
            "version": "1.0.7",
            "date": "2022-09-05",
            "fixed": [
                "Fix contracts-delete invalidating wrong query cache."
            ]
        },
        {
            "version": "1.0.6",
            "date": "2022-09-05",
            "added": [
                "Added page to send contract details via mail.",
                "Customer page: Show contract count.",
                "Cusomer page: Delete button if no contracts."
            ],
            "fixed": [
                "Invalidate queries on login.",
                "Customers page: do not show <more than one> if issuer/accmgr list is empty"
            ]
        }
    ]
}
