import { Divider, List, ListItem, PageSection, Title } from "@patternfly/react-core";
import * as React from 'react';
import * as changelogJson from "../changelog.json";

interface VersionEntry {
    version: string,
    date: string,
    added?: string[],
    changed?: string[],
    fixed?: string[],
    removed?: string[],
}

const ChangelogPage: React.FunctionComponent<{}> = () => {

    const renderList = (title: string, items?: string[]) => {
        if (items?.length) {
            return (
                <React.Fragment>
                    <Title headingLevel="h3">{title}</Title>
                    <List>
                        {items.map((i) => <ListItem>{i}</ListItem>)}
                    </List>
                </React.Fragment>
            )
        }
        return;
    }

    const renderVersion = (data: VersionEntry) => {
        if (data.added?.length || data.changed?.length || data.fixed?.length || data.removed?.length) {
            return (
                <PageSection>
                    <Title headingLevel="h2">[{data.version}] - {data.date}</Title>
                    <Divider /><br />
                    {renderList("Added", data.added)}
                    {renderList("Changed", data.changed)}
                    {renderList("Fixed", data.fixed)}
                    {renderList("Removed", data.removed)}
                </PageSection>
            )
        }
        return;
    }

    return (
        <PageSection>
            <Title headingLevel="h1" size="xl">{changelogJson.header}</Title><br />
            {changelogJson.versions.map((v) => renderVersion(v))}
        </PageSection>
    )
}

export { ChangelogPage };
