import * as React from 'react';
import {
    ActionGroup,
    Alert,
    AlertActionCloseButton,
    Button,
    Checkbox,
    DatePicker,
    Drawer,
    DrawerContent,
    DrawerContentBody,
    DrawerHead,
    DrawerPanelBody,
    DrawerPanelContent,
    Form,
    FormAlert,
    FormGroup,
    Grid,
    GridItem,
    isValidDate,
    PageSection,
    Spinner,
    TextInput,
    Title,
} from '@patternfly/react-core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import TrashIcon from '@patternfly/react-icons/dist/esm/icons/trash-icon';
import PencilAltIcon from '@patternfly/react-icons/dist/esm/icons/pencil-alt-icon';

import { apiPost, apiDelete, fetchContractOptions, apiPut } from '../fetcher';
import { ContractOption, CreateContractOptionRequest, ModifyContractOptionRequest } from 'linbit-api-fetcher';
import * as styles from '../lab.module.css';


const QUERY_KEY = ['contract-options'];

interface ContractOptionsTableProps {
    triggerEdit: (optionId: ContractOption) => void;
}

const ContractOptionsTable: React.FunctionComponent<ContractOptionsTableProps> = (props: ContractOptionsTableProps) => {
    const [responseError, setResponseError] = React.useState('');
    const queryClient = useQueryClient();

    function deleteContractOption(id: number) {
        apiDelete<String>("/manager/contract-options/" + id)
            .then((_resp) => {
                queryClient.invalidateQueries(QUERY_KEY);
            })
            .catch((reason) => setResponseError(reason.toString()));
    }

    function renderContractOptionRow(contractOptions: Map<number, ContractOption>) {
        var index: number = 0;
        return Array.from(contractOptions)
            .sort((a, b) => a[1].name.localeCompare(b[1].name))
            .map(([key, co]) => {
                index++;
                return (
                    <Tr key={co.id}>
                        <Td>{co.id}</Td>
                        <Td>{co.name}</Td>
                        <Td textCenter><Checkbox id={"default-" + co.id} checked={co.initial_value} /></Td>
                        <Td textCenter style={{ fontFamily: "RedHatMono" }}>{co.expiration_date}</Td>
                        <Td textCenter>
                            <Button variant='plain' className={styles.smallpadding} isSmall onClick={() => props.triggerEdit(co)}><PencilAltIcon /></Button>
                            <Button variant='plain' className={styles.smallpadding} isSmall onClick={() => deleteContractOption(co.id)}><TrashIcon /></Button>
                        </Td>
                    </Tr>
                )
            })
    }

    const { isLoading, isError, data, error } =
        useQuery<Map<number, ContractOption>, string>(['contract-options'], () => fetchContractOptions(false));

    if (isError) {
        return <Alert variant='danger' title={error} />
    }

    if (isLoading || !data) {
        return <Spinner isSVG />
    }

    const contractOptions = data ?? new Map()
    return (
        <React.Fragment>
            {responseError && (<Alert
                variant='danger'
                title={responseError}
                actionClose={<AlertActionCloseButton onClose={() => setResponseError('')} />}
            />)}
            <TableComposable
                variant='compact'
                className={styles.smalltable}
            >
                <Thead>
                    <Tr>
                        <Th width={10}>ID</Th>
                        <Th width={60}>Option</Th>
                        <Th textCenter>Default</Th>
                        <Th textCenter>Expiration Date</Th>
                        <Th textCenter>Action</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {renderContractOptionRow(contractOptions)}
                </Tbody>
            </TableComposable>
        </React.Fragment>
    )
}

const ContractOptions: React.FunctionComponent<{}> = () => {
    const [drawerExpanded, setDrawerExpanded] = React.useState(false);
    const [drawerMode, setDrawerMode] = React.useState('Create');
    const [optionId, setOptionId] = React.useState(0);
    const [optionName, setOptionName] = React.useState('');
    const [optionDefault, setOptionDefault] = React.useState(false);
    const [optionExpirationDate, setOptionExpirationDate] = React.useState('');
    const [optionExpirationEnabled, setOptionExpirationEnabled] = React.useState(false);
    const [responseError, setResponseError] = React.useState('');

    const queryClient = useQueryClient();

    const handleOptionNameChange = (value: string, _event: React.FormEvent<HTMLInputElement>) => {
        setOptionName(value);
    };

    const onChangeExpDate = (_event: React.FormEvent<HTMLInputElement>, str: string, date?: Date | undefined) => {
        if (date && isValidDate(date)) {
            setOptionExpirationDate(str);
        }
    }

    const handleSubmitClick = () => {
        console.log(drawerMode);
        if (drawerMode == 'Create') {
            let createreq: CreateContractOptionRequest = {
                name: optionName,
                initial_value: optionDefault,
                expiration_date: !optionExpirationEnabled ? undefined : optionExpirationDate
            }
            apiPost<ContractOption>('/manager/contract-options', createreq)
                .then((_resp) => {
                    queryClient.invalidateQueries(QUERY_KEY);
                    handleCancelClick();
                }).catch((reason) => {
                    setResponseError(reason.toString());
                });
        } else {
            let createreq: ModifyContractOptionRequest = {
                name: optionName,
                initial_value: optionDefault,
                expiration_date: !optionExpirationEnabled ? null : optionExpirationDate
            }
            apiPut<ContractOption>('/manager/contract-options/' + optionId, createreq)
                .then((_resp) => {
                    queryClient.invalidateQueries(QUERY_KEY);
                    handleCancelClick();
                }).catch((reason) => {
                    setResponseError(reason.toString());
                });
        }
    }

    const handleCancelClick = () => {
        setDrawerMode('Create');
        setOptionId(0);
        setOptionName('');
        setOptionDefault(false);
        setDrawerExpanded(false);
        setOptionExpirationEnabled(false);
        setOptionExpirationDate('');
        setResponseError('');
    }

    const triggerEdit = (contractOption: ContractOption) => {
        setDrawerMode('Edit');
        setDrawerExpanded(true);
        setOptionId(contractOption.id);
        setOptionName(contractOption.name);
        setOptionDefault(contractOption.initial_value);
        if (contractOption.expiration_date !== null) {
            setOptionExpirationEnabled(true);
            setOptionExpirationDate(contractOption.expiration_date || '');
        }
        console.log("Edit " + contractOption.expiration_date);
    }

    const panelContent = (
        <DrawerPanelContent>
            <DrawerHead>
                {drawerMode} Contract Option
            </DrawerHead>
            <DrawerPanelBody>
                <Form>
                    {responseError && (
                        <FormAlert>
                            <Alert
                                variant='danger'
                                title={responseError}
                                isInline
                            />
                        </FormAlert>
                    )}
                    <FormGroup
                        fieldId='opt-name'
                        isRequired
                        label='Option name'>
                        <TextInput
                            isRequired
                            type='text'
                            id='opt-name'
                            name='opt-name'
                            value={optionName}
                            onChange={handleOptionNameChange}
                        />
                    </FormGroup>
                    <FormGroup fieldId="check-default">
                        <Checkbox
                            label="Default?"
                            id="check-default"
                            name="check-default"
                            aria-label="default entry"
                            isChecked={optionDefault}
                            onChange={(checked, _event) => { setOptionDefault(checked) }}
                        />
                    </FormGroup>
                    <FormGroup fieldId='expiration-date' label='Expiration date'>
                        <Checkbox id="expiration-date-used"
                            isChecked={optionExpirationEnabled}
                            onChange={(checked, _event) => { setOptionExpirationEnabled(checked) }} />
                        <DatePicker
                            id='expiration-date'
                            isDisabled={!optionExpirationEnabled}
                            onChange={onChangeExpDate}
                            value={optionExpirationDate} />
                    </FormGroup>
                    <ActionGroup>
                        <Button variant="primary" onClick={handleSubmitClick} isDisabled={optionName === ''}>{drawerMode}</Button>
                        <Button variant="secondary" onClick={handleCancelClick}>Cancel</Button>
                    </ActionGroup>
                </Form>
            </DrawerPanelBody>
        </DrawerPanelContent>
    )

    return (
        <PageSection>
            <Title headingLevel="h1" size="xl">Contract Options</Title>
            <Button onClick={() => { setDrawerExpanded(!drawerExpanded) }} isSmall>Create</Button>
            <br /><br />
            <Grid>
                <GridItem span={10}>
                    <Drawer isExpanded={drawerExpanded}>
                        <DrawerContent panelContent={panelContent}>
                            <DrawerContentBody><ContractOptionsTable triggerEdit={triggerEdit} /></DrawerContentBody>
                        </DrawerContent>
                    </Drawer>
                </GridItem>
            </Grid>
        </PageSection>
    )
}

export { ContractOptions }
